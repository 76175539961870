<template>
    <section class="d-flex">
        <div v-for="data in pedidos" :key="data.fecha" class="">
            <div class="mx-2 h-100">
                <div :class="menorAHoy(data.fecha) ? 'border-general' : 'border-general2'" class="my-2 text-general2 f-600 f-14 br-5 py-2 position-relative text-center">
                    <div v-if="menorAHoy(data.fecha)" class="circle" />
                    {{ formatearDate(data.fecha) }}
                </div>
                <div class="d-flex h-100">
                    <div v-for="horario in data.horarios" :key="`${data.fecha}:${horario.horario}`" class="mt-3 mx-0 width-column content-column px-2">
                        <!-- Rango de Hora -->
                        <div class="col-12 mx-0 bg-light-f5 br-5 py-2 text-center tres-puntos d-middle justify-center cr-pointer" @click="modalAsignarOperadorTablero(horario, data.fecha )">
                            <span class="text-general2 f-600">{{ formatDate(`${data.fecha} ${horario.horario}:00`) }}</span>
                            <div class="cant-orders border-general d-flex justify-center align-items-center f-14 ml-3 br-20 p-1" v-text="horario.pedidos.length" />
                        </div>
                        <!-- Estados -->
                        <div v-for="pedidoI in horario.pedidos" :key="pedidoI.id">
                            <el-popover
                            placement="bottom"
                            trigger="hover"
                            :open-delay="500"
                            @show="onShow(pedidoI)"
                            >
                                <div class="row mx-0">
                                    <div class="col-12">
                                        <p class="text-general2 f-15 f-600">
                                            Cedis: {{ _.get(pedido, 'cedis_nombre', 'Sin nombre') }}
                                        </p>
                                        <p class="text-general2 f-15 f-600">
                                            Valor: {{ convertMoney(_.get(pedido, 'valor_final', '0'), _.get(pedido, 'idm_moneda', 1)) }}
                                        </p>
                                        <p class="text-general2 f-15 mt-3">
                                            Leechero: {{ _.get(pedido, 'tienda_nombre', 'Sin nombre') }}
                                        </p>
                                        <p v-if="_.get(pedido, 'cliente_entrega_directa', false)" class="text-general2 f-15">
                                            Cliente: {{ _.get(pedido, 'nombre_cliente', 'Sin nombre') }}
                                        </p>
                                        <p class="text-general2 f-15">
                                            Teléfono: {{ _.get(pedido, 'telefono', 'No registra') }}
                                        </p>
                                        <p class="text-general2 f-15">
                                            Dirección: {{ _.get(pedido, 'direccion', 'Sin dirección') }}
                                        </p>
                                        <p v-if="_.get(pedido, 'id_delivery', false)" class="text-general2 f-15 mt-3">
                                            Delivery: {{ _.get(pedido, 'delivery_nombre', 'Sin nombre') }}
                                        </p>
                                    </div>
                                </div>
                                <div slot="reference">
                                    <div class="br-5 px-2 py-2 text-white tres-puntos mt-2 mb-1 text-center cr-pointer" :class="estadoPedido(pedidoI.estado)" @click="onClick(pedidoI)">
                                        <i :class="iconoPedido(pedidoI.estado)" />
                                        <b> {{ pedidoI.id }}</b>
                                    </div>
                                </div>
                            </el-popover>
                            <div class="row mx-0 justify-content-center">
                                <i v-if="pedidoI.intentos > 1" class="icon-history text-general" />
                                <i v-if="pedidoI.agotado && pedidoI.estado != 3" class="icon-package-variant f-18 text-general-red" />
                                <i v-if="pedidoI.en_entrega" class="icon-truck f-18 text-green" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal-asignar-operador-tablero ref="modalAsignarOperadorTablero" @consultar="$emit('listar')" />
        <modal-detalle-pedido ref="modalDetalle" @update="$emit('listar')" />
    </section>
</template>

<script>
import moment from 'moment'
import tablero from '~/services/pedidos/tablero'

const estados = {
    0: { color: 'nothing', icon: 'nothing' },
    1: { color: 'bg-yellow', icon: 'icon-receipt f-20' },
    2: { color: 'bg-blue', icon: 'icon-ok-circled f-20' },
    201: { color: 'bg-pink', icon: 'icon-cart-arrow-down f-20' },
    202: { color: 'bg-purple', icon: 'icon-shopping-outline f-20' },
    3: { color: 'bg-green', icon: 'icon-truck f-20' },
}
export default {
    components: {
        modalAsignarOperadorTablero: () => import('../partials/modalAsignarOperadorTablero'),
        modalDetallePedido: () => import('../partials/modalDetallesRuta'),
    },
    props: {
        verCedis: {
            type: Boolean,
            default: () => false
        },
        pedidos: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            pedido: {}
        }
    },
    methods: {
        modalAsignarOperadorTablero(horario, fecha){
            if (this.$route.name == 'admin.rutas-entrega-2.pedidos-proceso.asignados') return ;
            let ids = _.flatten(horario.pedidos.map(({id}) => id))
            if(!ids.length) return
            this.$refs.modalAsignarOperadorTablero.toggle(ids, fecha, `${horario.horario}:00`)
        },
        menorAHoy: (fecha) => moment(fecha).isBefore(moment().format('YYYY-MM-DD')),
        estadoPedido: (state = null) =>  state ? estados[state].color : estados[0].color,
        iconoPedido: (state = null) =>  state ? estados[state].icon : estados[0].icon,
        formatDate: (fecha) => moment(fecha).format('hh:mm a'),
        formatearDate(fecha){
            if(moment(fecha).isSame(moment().format('YYYY-MM-DD'))) return 'Hoy'
            if((moment(fecha).isSame(moment().add(1, 'day').format('YYYY-MM-DD')))) return 'Mañana'
            if((moment(fecha).isSame(moment().add(2, 'day').format('YYYY-MM-DD')))) return 'Pasado mañana'
            return this.formatearFecha(fecha)
        },
        onClick(item){
            this.$refs.modalDetalle.toggle(item.id)
        },
        async onShow({ id }){
            try {
                const { data } = await tablero.showPedido(id)
                this.pedido = data
            } catch (error){
                this.error_catch(error)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.width-column{
    width: 200px;
}
.content-column{
    border-left: 2px solid var(--color-db);
    border-right: 2px solid var(--color-db);
}
.circle{
    width: 15px;
    position: absolute;
    height: 15px;
    top: -4px;
    right: -5px;
    background-color: var(--color-general);
    border-radius: 50%;
}
.cant-orders{
    min-width:25px;
    height:25px;
    background-color: white;
    color: #707070;
    border: 1px solid #707070;
}
</style>
<style lang="scss" scoped>
.bg-yellow{ background-color: #FF9D32; }
.text-yellow{ color: #FF9D32; }
.bg-blue{ background-color: #0892DD; }
.text-blue{ color: #0892DD;}
.bg-pink{ background-color: #D6017B; }
.text-pink{ color: #D6017B;}
.bg-purple{ background-color: #6D3CE1; }
.text-purple{ color: #6D3CE1; }
.bg-green{ background-color: #28D07B;}
.text-green{ color: #28D07B; }
</style>
